// // import React, { useEffect, useState } from 'react';

// // function App() {
// //   const [externalLink, setExternalLink] = useState(null);
// //   const urlParams = new URLSearchParams(window.location.search);
// //   const transactionId = urlParams.get('transaction_id');

// //   useEffect(() => {
// //     document.title = "Gateway Connect"; // Set site title

// //     async function fetchTransactionDetails() {
// //       try {
// //         const response = await fetch(`https://payin.payinfy.com/api/transaction-details/${transactionId}/`, {
// //           headers: { 'Content-Type': 'application/json' }
// //         });
// //         const data = await response.json();
// //         if (data.status === 'SUCCESS' && data.transaction.external_payment_link) {
// //           setExternalLink(data.transaction.external_payment_link);
// //         } else {
// //           console.error('Failed to retrieve external link:', data);
// //         }
// //       } catch (error) {
// //         console.error('Error fetching transaction details:', error);
// //       }
// //     }

// //     if (transactionId) {
// //       fetchTransactionDetails();
// //     }
// //   }, [transactionId]);

// //   return (
// //     <div style={{ height: '100vh', margin: 0, padding: 0 }}>
// //       {externalLink ? (
// //         <iframe
// //           src={externalLink}
// //           title="Payment Widget"
// //           style={{ width: '100%', height: '100%', border: 'none' }}
// //         ></iframe>
// //       ) : (
// //         <div>Loading payment widget...</div>
// //       )}
// //     </div>
// //   );
// // }

// // export default App;



// import React, { useEffect, useState } from 'react';

// function App() {
//   const [externalLink, setExternalLink] = useState(null);
//   const [successUrl, setSuccessUrl] = useState(null);
//   const [failureUrl, setFailureUrl] = useState(null);
//   const [createdAt, setCreatedAt] = useState(null);

//   const urlParams = new URLSearchParams(window.location.search);
//   const transactionId = urlParams.get('transaction_id');

//   useEffect(() => {
//     document.title = "Gateway Connect";

//     async function fetchTransactionDetails() {
//       try {
//         const response = await fetch(`https://payin.payinfy.com/api/transaction-details/${transactionId}/`, {
//           headers: { 'Content-Type': 'application/json' }
//         });
//         const data = await response.json();
//         if (data.status === 'SUCCESS' && data.transaction.external_payment_link) {
//           setExternalLink(data.transaction.external_payment_link);
//           setSuccessUrl(data.transaction.success_redirect_url);
//           setFailureUrl(data.transaction.failure_redirect_url);
//           setCreatedAt(data.transaction.created_at);
//         } else {
//           console.error('Failed to retrieve external link or transaction details:', data);
//         }
//       } catch (error) {
//         console.error('Error fetching transaction details:', error);
//       }
//     }

//     if (transactionId) {
//       fetchTransactionDetails();
//     }
//   }, [transactionId]);

//   useEffect(() => {
//     if (transactionId && createdAt && (successUrl || failureUrl)) {
//       // Set a timeout for 15 minutes (900000 ms)
//       const timeoutId = setTimeout(async () => {
//         try {
//           // Attempt to fail the transaction if still pending
//           const failResp = await fetch(`https://payin.payinfy.com/api/fail-if-pending/${transactionId}/`, {
//             method: 'POST',
//             headers: { 'Content-Type': 'application/json' }
//           });
//           const failData = await failResp.json();

//           if (failData.status === 'Success') {
//             // Transaction was pending and is now failed
//             if (failureUrl) {
//               window.location.href = `${failureUrl}?transaction_id=${transactionId}`;
//             }
//           } else {
//             // Not pending or already succeeded/failed
//             // Check the final status once
//             const statusResp = await fetch(`https://payin.payinfy.com/api/check-payment-status/${transactionId}/`, {
//               headers: { 'Content-Type': 'application/json' }
//             });
//             const statusData = await statusResp.json();

//             if (statusData.status === 'SUCCESS' && successUrl) {
//               window.location.href = `${successUrl}?transaction_id=${transactionId}`;
//             } else {
//               // If not success, consider it failed or no info
//               if (failureUrl) {
//                 window.location.href = `${failureUrl}?transaction_id=${transactionId}`;
//               }
//             }
//           }
//         } catch (e) {
//           console.error("Error attempting to fail-if-pending:", e);
//           // On error, redirect to failure as fallback
//           if (failureUrl) {
//             window.location.href = `${failureUrl}?transaction_id=${transactionId}`;
//           }
//         }
//       }, 900000); // 15 minutes

//       return () => clearTimeout(timeoutId);
//     }
//   }, [transactionId, createdAt, successUrl, failureUrl]);

//   return (
//     <div style={{ height: '100vh', margin: 0, padding: 0 }}>
//       {externalLink ? (
//         <iframe
//           src={externalLink}
//           title="Payment Widget"
//           style={{ width: '100%', height: '100%', border: 'none' }}
//         ></iframe>
//       ) : (
//         <div>Processing your payment...</div>
//       )}
//     </div>
//   );
// }

// export default App;


// import React, { useEffect, useState } from 'react';

// function App() {
//   const [externalLink, setExternalLink] = useState(null);
//   const [successUrl, setSuccessUrl] = useState(null);
//   const [failureUrl, setFailureUrl] = useState(null);
//   const [createdAt, setCreatedAt] = useState(null);

//   const urlParams = new URLSearchParams(window.location.search);
//   const transactionId = urlParams.get('transaction_id');

//   useEffect(() => {
//     document.title = "Gateway Connect";

//     async function fetchTransactionDetails() {
//       try {
//         const response = await fetch(`https://payin.payinfy.com/api/transaction-details/${transactionId}/`, {
//           headers: { 'Content-Type': 'application/json' }
//         });
//         const data = await response.json();
//         if (data.status && data.transaction) {
//           // Set success/failure URL and createdAt regardless of external_payment_link presence
//           setSuccessUrl(data.transaction.success_redirect_url);
//           setFailureUrl(data.transaction.failure_redirect_url);
//           setCreatedAt(data.transaction.created_at);

//           if (data.transaction.external_payment_link) {
//             setExternalLink(data.transaction.external_payment_link);
//           }
//         } else {
//           console.error('Failed to retrieve transaction details:', data);
//         }
//       } catch (error) {
//         console.error('Error fetching transaction details:', error);
//       }
//     }

//     if (transactionId) {
//       fetchTransactionDetails();
//     }
//   }, [transactionId]);

//   useEffect(() => {
//     if (transactionId && createdAt && (successUrl || failureUrl)) {
//       const timeoutId = setTimeout(async () => {
//         try {
//           // Attempt to fail if pending
//           const failResp = await fetch(`https://payin.payinfy.com/api/fail-if-pending/${transactionId}/`, {
//             method: 'POST',
//             headers: { 'Content-Type': 'application/json' }
//           });
//           const failData = await failResp.json();

//           if (failData.status === 'Success') {
//             // Now failed, redirect to failure
//             if (failureUrl) {
//               window.location.href = `${failureUrl}?transaction_id=${transactionId}`;
//             }
//           } else {
//             // Check final status
//             const statusResp = await fetch(`https://payin.payinfy.com/api/check-payment-status/${transactionId}/`, {
//               headers: { 'Content-Type': 'application/json' }
//             });
//             const statusData = await statusResp.json();

//             if (statusData.status === 'SUCCESS' && successUrl) {
//               window.location.href = `${successUrl}?transaction_id=${transactionId}`;
//             } else {
//               if (failureUrl) {
//                 window.location.href = `${failureUrl}?transaction_id=${transactionId}`;
//               }
//             }
//           }
//         } catch (e) {
//           console.error("Error attempting to fail-if-pending:", e);
//           if (failureUrl) {
//             window.location.href = `${failureUrl}?transaction_id=${transactionId}`;
//           }
//         }
//       }, 900000); // 15 minutes

//       return () => clearTimeout(timeoutId);
//     }
//   }, [transactionId, createdAt, successUrl, failureUrl]);

//   return (
//     <div style={{ height: '100vh', margin: 0, padding: 0 }}>
//       {externalLink ? (
//         <iframe
//           src={externalLink}
//           title="Payment Widget"
//           style={{ width: '100%', height: '100%', border: 'none' }}
//         ></iframe>
//       ) : (
//         <div>Processing your payment...</div>
//       )}
//     </div>
//   );
// }

// export default App;



// import React, { useEffect, useState } from 'react';

// function App() {
//   const [externalLink, setExternalLink] = useState(null);
//   const [successUrl, setSuccessUrl] = useState(null);
//   const [failureUrl, setFailureUrl] = useState(null);
//   const [createdAt, setCreatedAt] = useState(null);
//   const [statusCheckInterval, setStatusCheckInterval] = useState(null);

//   const urlParams = new URLSearchParams(window.location.search);
//   const transactionId = urlParams.get('transaction_id');

//   useEffect(() => {
//     document.title = "Gateway Connect";

//     async function fetchTransactionDetails() {
//       try {
//         const response = await fetch(`https://payin.payinfy.com/api/transaction-details/${transactionId}/`, {
//           headers: { 'Content-Type': 'application/json' }
//         });
//         const data = await response.json();

//         if (data.status && data.transaction) {
//           setSuccessUrl(data.transaction.success_redirect_url);
//           setFailureUrl(data.transaction.failure_redirect_url);
//           setCreatedAt(data.transaction.created_at);

//           if (data.transaction.external_payment_link) {
//             setExternalLink(data.transaction.external_payment_link);
//           }

//           // Start periodic polling to check for success if needed
//           const intervalId = setInterval(async () => {
//             // Check the transaction status every 10 seconds
//             const statusResp = await fetch(`https://payin.payinfy.com/api/check-payment-status/${transactionId}/`, {
//               headers: { 'Content-Type': 'application/json' }
//             });
//             const statusData = await statusResp.json();

//             if (statusData.status === 'SUCCESS' && successUrl) {
//               clearInterval(intervalId);
//               window.location.href = `${successUrl}?transaction_id=${transactionId}`;
//             } else if (statusData.status === 'FAILED' && failureUrl) {
//               clearInterval(intervalId);
//               window.location.href = `${failureUrl}?transaction_id=${transactionId}`;
//             }
//           }, 1000); // every 10 seconds

//           setStatusCheckInterval(intervalId);
//         } else {
//           console.error('Failed to retrieve transaction details:', data);
//         }
//       } catch (error) {
//         console.error('Error fetching transaction details:', error);
//       }
//     }

//     if (transactionId) {
//       fetchTransactionDetails();
//     }

//     return () => {
//       if (statusCheckInterval) clearInterval(statusCheckInterval);
//     };
//   }, [transactionId]);

//   useEffect(() => {
//     if (transactionId && createdAt && (successUrl || failureUrl)) {
//       const timeoutId = setTimeout(async () => {
//         try {
//           // Attempt to fail if pending after 15 minutes
//           const failResp = await fetch(`https://payin.payinfy.com/api/fail-if-pending/${transactionId}/`, {
//             method: 'POST',
//             headers: { 'Content-Type': 'application/json' }
//           });
//           const failData = await failResp.json();

//           if (failData.status === 'Success') {
//             // Now failed, redirect to failure
//             if (failureUrl) {
//               window.location.href = `${failureUrl}?transaction_id=${transactionId}`;
//             }
//           } else {
//             // Check final status just once more
//             const statusResp = await fetch(`https://payin.payinfy.com/api/check-payment-status/${transactionId}/`, {
//               headers: { 'Content-Type': 'application/json' }
//             });
//             const statusData = await statusResp.json();

//             if (statusData.status === 'SUCCESS' && successUrl) {
//               window.location.href = `${successUrl}?transaction_id=${transactionId}`;
//             } else {
//               if (failureUrl) {
//                 window.location.href = `${failureUrl}?transaction_id=${transactionId}`;
//               }
//             }
//           }
//         } catch (e) {
//           console.error("Error attempting to fail-if-pending:", e);
//           if (failureUrl) {
//             window.location.href = `${failureUrl}?transaction_id=${transactionId}`;
//           }
//         }
//       }, 900000); // 15 minutes

//       return () => clearTimeout(timeoutId);
//     }
//   }, [transactionId, createdAt, successUrl, failureUrl]);

//   return (
//     <div style={{ height: '100vh', margin: 0, padding: 0 }}>
//       {externalLink ? (
//         <iframe
//           src={externalLink}
//           title="Payment Widget"
//           style={{ width: '100%', height: '100%', border: 'none' }}
//         ></iframe>
//       ) : (
//         <div>Processing your payment...</div>
//       )}
//     </div>
//   );
// }

// export default App;



// import React, { useEffect, useState } from 'react';

// function App() {
//   const [externalLink, setExternalLink] = useState(null);
//   const [successUrl, setSuccessUrl] = useState(null);
//   const [failureUrl, setFailureUrl] = useState(null);
//   const [createdAt, setCreatedAt] = useState(null);
//   const [statusCheckInterval, setStatusCheckInterval] = useState(null);

//   const urlParams = new URLSearchParams(window.location.search);
//   const transactionId = urlParams.get('transaction_id');

//   useEffect(() => {
//     document.title = "Gateway Connect";

//     async function fetchTransactionDetails() {
//       try {
//         const response = await fetch(`https://payin.payinfy.com/api/transaction-details/${transactionId}/`, {
//           headers: { 'Content-Type': 'application/json' }
//         });
//         const data = await response.json();

//         if (data.status && data.transaction) {
//           setSuccessUrl(data.transaction.success_redirect_url);
//           setFailureUrl(data.transaction.failure_redirect_url);
//           setCreatedAt(data.transaction.created_at);

//           if (data.transaction.external_payment_link) {
//             setExternalLink(data.transaction.external_payment_link);
//           }

//           // Start periodic polling to check for success status
//           const intervalId = setInterval(async () => {
//             const statusResp = await fetch(`https://payin.payinfy.com/api/check-payment-status/${transactionId}/`, {
//               headers: { 'Content-Type': 'application/json' }
//             });
//             const statusData = await statusResp.json();

//             // Update successUrl and failureUrl from statusData if provided
//             // This ensures you have the most up-to-date redirect URLs
//             if (statusData.success_redirect_url) {
//               setSuccessUrl(statusData.success_redirect_url);
//             }
//             if (statusData.failure_redirect_url) {
//               setFailureUrl(statusData.failure_redirect_url);
//             }

//             if (statusData.status === 'SUCCESS' && successUrl) {
//               clearInterval(intervalId);
//               window.location.href = `${successUrl}?transaction_id=${transactionId}`;
//             } else if (statusData.status === 'FAILED' && failureUrl) {
//               clearInterval(intervalId);
//               window.location.href = `${failureUrl}?transaction_id=${transactionId}`;
//             }
//           }, 10000); // check every 10 seconds (adjust as needed)

//           setStatusCheckInterval(intervalId);
//         } else {
//           console.error('Failed to retrieve transaction details:', data);
//         }
//       } catch (error) {
//         console.error('Error fetching transaction details:', error);
//       }
//     }

//     if (transactionId) {
//       fetchTransactionDetails();
//     }

//     return () => {
//       if (statusCheckInterval) clearInterval(statusCheckInterval);
//     };
//   }, [transactionId]);

//   useEffect(() => {
//     if (transactionId && createdAt && (successUrl || failureUrl)) {
//       const timeoutId = setTimeout(async () => {
//         try {
//           // After 15 minutes, try failing if still pending
//           const failResp = await fetch(`https://payin.payinfy.com/api/fail-if-pending/${transactionId}/`, {
//             method: 'POST',
//             headers: { 'Content-Type': 'application/json' }
//           });
//           const failData = await failResp.json();

//           if (failData.status === 'Success') {
//             // Now failed, redirect to failure
//             if (failureUrl) {
//               window.location.href = `${failureUrl}?transaction_id=${transactionId}`;
//             }
//           } else {
//             // Check final status once more
//             const statusResp = await fetch(`https://payin.payinfy.com/api/check-payment-status/${transactionId}/`, {
//               headers: { 'Content-Type': 'application/json' }
//             });
//             const statusData = await statusResp.json();

//             // Update URLs again if provided by final check
//             if (statusData.success_redirect_url) {
//               setSuccessUrl(statusData.success_redirect_url);
//             }
//             if (statusData.failure_redirect_url) {
//               setFailureUrl(statusData.failure_redirect_url);
//             }

//             if (statusData.status === 'SUCCESS' && successUrl) {
//               window.location.href = `${successUrl}?transaction_id=${transactionId}`;
//             } else {
//               if (failureUrl) {
//                 window.location.href = `${failureUrl}?transaction_id=${transactionId}`;
//               }
//             }
//           }
//         } catch (e) {
//           console.error("Error attempting to fail-if-pending:", e);
//           if (failureUrl) {
//             window.location.href = `${failureUrl}?transaction_id=${transactionId}`;
//           }
//         }
//       }, 900000); // 15 minutes

//       return () => clearTimeout(timeoutId);
//     }
//   }, [transactionId, createdAt, successUrl, failureUrl]);

//   return (
//     <div style={{ height: '100vh', margin: 0, padding: 0 }}>
//       {externalLink ? (
//         <iframe
//           src={externalLink}
//           title="Payment Widget"
//           style={{ width: '100%', height: '100%', border: 'none' }}
//         ></iframe>
//       ) : (
//         <div>Processing your payment...</div>
//       )}
//     </div>
//   );
// }

// export default App;



import React, { useEffect, useState } from 'react';

function App() {
  const [externalLink, setExternalLink] = useState(null);
  const [successUrl, setSuccessUrl] = useState(null);
  const [failureUrl, setFailureUrl] = useState(null);
  const [createdAt, setCreatedAt] = useState(null);
  const [statusCheckInterval, setStatusCheckInterval] = useState(null);

  const urlParams = new URLSearchParams(window.location.search);
  const transactionId = urlParams.get('transaction_id');

  useEffect(() => {
    document.title = "Gateway Connect";

    async function fetchTransactionDetails() {
      try {
        const response = await fetch(`https://payin.payinfy.com/api/transaction-details/${transactionId}/`, {
          headers: { 'Content-Type': 'application/json' }
        });
        const data = await response.json();

        if (data.status && data.transaction) {
          setSuccessUrl(data.transaction.success_redirect_url);
          setFailureUrl(data.transaction.failure_redirect_url);
          setCreatedAt(data.transaction.created_at);

          if (data.transaction.external_payment_link) {
            setExternalLink(data.transaction.external_payment_link);
          }

          // Start periodic polling to check for success status
          const intervalId = setInterval(async () => {
            const statusResp = await fetch(`https://payin.payinfy.com/api/check-payment-status/${transactionId}/`, {
              headers: { 'Content-Type': 'application/json' }
            });
            const statusData = await statusResp.json();

            // Use statusData directly for redirection to avoid async setState issues
            if (statusData.status === 'SUCCESS' && statusData.success_redirect_url) {
              clearInterval(intervalId);
              window.location.href = `${statusData.success_redirect_url}?transaction_id=${transactionId}`;
            } else if (statusData.status === 'FAILED' && statusData.failure_redirect_url) {
              clearInterval(intervalId);
              window.location.href = `${statusData.failure_redirect_url}?transaction_id=${transactionId}`;
            }
          }, 1000); // check every 10 seconds

          setStatusCheckInterval(intervalId);
        } else {
          console.error('Failed to retrieve transaction details:', data);
        }
      } catch (error) {
        console.error('Error fetching transaction details:', error);
      }
    }

    if (transactionId) {
      fetchTransactionDetails();
    }

    return () => {
      if (statusCheckInterval) clearInterval(statusCheckInterval);
    };
  }, [transactionId]);

  useEffect(() => {
    if (transactionId && createdAt && (successUrl || failureUrl)) {
      const timeoutId = setTimeout(async () => {
        try {
          // After 15 minutes, fail if pending
          const failResp = await fetch(`https://payin.payinfy.com/api/fail-if-pending/${transactionId}/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
          });
          const failData = await failResp.json();

          if (failData.status === 'Success') {
            // Now failed, redirect to failure
            if (failureUrl) {
              window.location.href = `${failureUrl}?transaction_id=${transactionId}`;
            }
          } else {
            // Check final status once more
            const statusResp = await fetch(`https://payin.payinfy.com/api/check-payment-status/${transactionId}/`, {
              headers: { 'Content-Type': 'application/json' }
            });
            const statusData = await statusResp.json();

            // Directly use statusData for final redirect
            if (statusData.status === 'SUCCESS' && statusData.success_redirect_url) {
              window.location.href = `${statusData.success_redirect_url}?transaction_id=${transactionId}`;
            } else {
              if (statusData.failure_redirect_url) {
                window.location.href = `${statusData.failure_redirect_url}?transaction_id=${transactionId}`;
              }
            }
          }
        } catch (e) {
          console.error("Error attempting to fail-if-pending:", e);
          if (failureUrl) {
            window.location.href = `${failureUrl}?transaction_id=${transactionId}`;
          }
        }
      }, 900000); // 15 minutes

      return () => clearTimeout(timeoutId);
    }
  }, [transactionId, createdAt, successUrl, failureUrl]);

  return (
    <div style={{ height: '100vh', margin: 0, padding: 0 }}>
      {externalLink ? (
        <iframe
          src={externalLink}
          title="Payment Widget"
          style={{ width: '100%', height: '100%', border: 'none' }}
        ></iframe>
      ) : (
        <div>Processing your payment...</div>
      )}
    </div>
  );
}

export default App;
